import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import styled from '@emotion/styled';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import SEO from '../components/SEO';
import Header from '../components/Header';
import Content from '../components/Content';
import Slices from '../slices/Slices';
import HireUsBase from '../components/HireUsBase';

const Wrapper = styled.div`
  h1 {
    font-size: ${props => props.theme.fontSize.headingLarge};
    line-height: ${props => props.theme.lineHeight.headingLarge};
    font-weight: 900;
    padding: 0 0 4.2rem 0;
    border-bottom: 6px solid ${props => props.theme.colors.lightgreen};
    display: inline-block;
    margin: 16rem 0;
  }
  h3 {
    font-size: ${props => props.theme.fontSize.heading2};
    line-height: ${props => props.theme.lineHeight.heading2};
    font-weight: 900;
    margin: 0 0 3rem 0;
    color: ${props => props.theme.colors.violet};
  }
  p {
    font-size: ${props => props.theme.fontSize.textBody};
    line-height: ${props => props.theme.lineHeight.textBody};
    font-weight: 400;
    margin: 3rem 0 0 0;
    color: ${props => props.theme.colors.colorTextGrey};
    &:first-child {
      margin: 0;
    }
  }
  img {
    display: block;
  }
  h4 {
    ${props => props.theme.fontSize.heading3};
    line-height: 1.2;
    font-weight: 900;
  }
`

const Hero = styled.div`
  padding: 0;
  text-align: center;
`

const SinglePage = ({ data }) => {
  const {
    singlePage: {
      data: { name, description, body, projects },
    },
  } = data
  const { specializations } = data.allPrismicSpecializationLinks.edges[0].node.data
  return (
    <Layout>
      <SEO />
      <Helmet title={name} description={description} />
      <Wrapper>
        <Hero>
          <Header specializations={specializations} menu />
          <Content>
            <h1>{name}</h1>
          </Content>
        </Hero>
        <Slices body={body} projects={projects} noBottomPadding projectTeam />
      </Wrapper>
      <HireUsBase />
      <Footer />
    </Layout>
  );
};

export default SinglePage;

SinglePage.propTypes = {
  data: PropTypes.shape({
    singlePage: PropTypes.object.isRequired,
    allPrismicSpecializationLinks: PropTypes.object.isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
  query singlePagePageSlug($uid: String!) {
    singlePage: prismicSinglePage(uid: { eq: $uid }) {
      uid
      data {
        name
        description
        body {
          ... on PrismicSinglePageBodyText {
            slice_type
            primary {
              content {
                html
              }
            }
          }
          ... on PrismicSinglePageBodyTextWithBackground {
            slice_type
            primary {
              content {
                html
              }
            }
          }
          ... on PrismicSinglePageBodyCases {
            id
            slice_type
          }
        }
        projects {
          project {
            document {
              uid
              data {
                title {
                  text
                }
                client {
                  text
                }
                description {
                  text
                }
                previewi_img {
                  url
                }
                tech_stack {
                  technology {
                    id
                    document {
                      uid
                      data {
                        name
                      }
                    }
                  }
                }
                project_type {
                  specialization {
                    id
                    document {
                      uid
                      data {
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicSpecializationLinks {
      edges {
        node {
          data {
            specializations {
              specialization {
                document {
                  data {
                    name
                  }
                  uid
                }
              }
            }
          }
        }
      }
    }
  }
`;
